import { Grid, Typography, Box, Divider } from '@mui/material'

import useStyles from '@components/common/WrapperBlock/styles'

const WrapperBlock = ({
  children,
  title,
  description = '',
  status,
  extraHeaderBlock,
  extraHeaderBlockTabs,
  preFilters,
  filters,
  alternativeChildren = false,
  data_testid = '',
  height = 'auto'
}) => {
  const classes = useStyles()

  const styleWithExtraAndNoDescription = {
    display: 'flex',
    alignItems: 'center'
  }

  return (
    <Grid
      container
      className={classes.root}
      sx={{ height }}
      data-testid={data_testid}
    >
      {/* <Stack sx={{ height: 'min-content', width: '100%' }}> */}
      {(title || extraHeaderBlock) && (
        <Grid
          container
          item
          xs={12}
          className={classes.informations}
          sx={
            !description?.length && extraHeaderBlock
              ? { ...styleWithExtraAndNoDescription }
              : {}
          }
        >
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexWrap: 'nowrap'
            }}
          >
            {title && (
              <div>
                {status ? (
                  <Box display="flex" alignItems="center">
                    <Typography variant="subtitle1" color="text.secondary">
                      {title}
                    </Typography>
                    <Box ml={3}>{status}</Box>
                  </Box>
                ) : (
                  <Typography variant="subtitle1" color="text.secondary">
                    {title}
                  </Typography>
                )}

                {description && (
                  <Typography variant="body2" color="text.primary">
                    {description}
                  </Typography>
                )}
              </div>
            )}
            {extraHeaderBlock && (
              <Box sx={{ width: 'fit-content', ml: 1 }}>{extraHeaderBlock}</Box>
            )}
          </Grid>
          {extraHeaderBlockTabs && (
            <Grid item xs={12} mt={2}>
              {extraHeaderBlockTabs}
            </Grid>
          )}
        </Grid>
      )}
      {(preFilters || filters) && (
        <Grid
          item
          xs={12}
          className={classes.filters}
          sx={
            preFilters
              ? { py: '0 !important', overflowX: 'auto', overflowY: 'hidden' }
              : { overflowX: 'auto', overflowY: 'hidden' }
          }
        >
          {preFilters && preFilters}
          {preFilters && <Divider orientation="vertical" />}
          {preFilters ? <Box ml={1}>{filters}</Box> : filters}
        </Grid>
      )}
      <Grid
        item
        xs={12}
        className={
          alternativeChildren
            ? classes.alternative
            : children
            ? classes.children
            : classes.noChildren
        }
      >
        {children}
      </Grid>
      {/* </Stack> */}
    </Grid>
  )
}

export default WrapperBlock
