import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.elements.white,
    borderRadius: theme.spacing(1)
  },
  informations: {
    padding: theme.spacing(2),
    paddingBottom: 0
  },
  filters: {
    height: theme.spacing(8.5),
    marginTop: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.elements.main}`,
    borderBottom: `1px solid ${theme.palette.elements.main}`,
    backgroundColor: theme.palette.elements.light,
    display: 'flex',
    alignItems: 'center'
  },
  children: {
    padding: theme.spacing(2),
    overflow: 'auto'
  },
  alternative: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.elements.main}`,
    backgroundColor: theme.palette.elements.light,
    borderBottomLeftRadius: theme.spacing(1),
    borderBottomRightRadius: theme.spacing(1),
    overflow: 'auto'
  },
  noChildren: {
    marginTop: theme.spacing(2)
  }
}))

export default useStyles
